import React from "react";
import Quote from "./Quote";

const QuoteModal = ({ open, onClose }) => {
  console.log("open", "open");
  if (!open) return null;
  return (
    <div onClick={onClose} style={{ zIndex: 10000 }} className="overlay">
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="modalContainer"
      >
        <div className="modalRight">
          <p className="closeBtn" onClick={onClose}>
            X
          </p>
          <div className="content">
            <Quote onClose={onClose} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuoteModal;
