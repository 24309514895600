import React, { useState } from "react";

const Modal = ({ open, onClose, closeQuotation, invokeQuotation }) => {
  if (!open) return null;
  return (
    <div onClick={onClose} className="overlay">
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="modalContainer"
      >
        <div className="modalRight">
          <p className="closeBtn" onClick={onClose}>
            X
          </p>
          <div className="content">
            <p>Do you want to get the best deal?</p>
            <h1>CONTACT US</h1>
            <p>Schedule a meeting</p>
          </div>
          <div className="btnContainer">
            <button
              className="btnPrimary"
              onClick={() => {
                invokeQuotation();
                onClose();
              }}
            >
              <span className="bold">YES</span>, I want to get the BEST deal!
            </button>
            <button className="btnOutline" onClick={onClose}>
              <span className="bold">NO</span>, thanks
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
