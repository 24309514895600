import React from "react";

const ENDPOINT =
  process.env.NODE_ENV === "production" || process.env.USER === "ubuntu"
    ? "https://grp07api.webpark.tech"
    : "http://localhost:3002";

const Quote = ({ onClose }) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      name: e.target["name"].value,
      email: e.target["email"].value,
      availability: e.target["time_available"].value,
      budget: e.target["budget"].value,
    };

    fetch(`${ENDPOINT}/api/quotations`, {
      method: "POST",
      mode: "cors",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        alert("Your quotation is saved!");
        onClose();
      })
      .catch((e) => console.log(e.message));
    console.log(data);
  };

  return (
    <form id="quote" name="quote" onSubmit={handleSubmit}>
      <div className="form-group">
        <label>Name:</label>
        <input type="text" className="form-control" id="name" name="name" />
      </div>
      <div className="form-group">
        <label>Email:</label>
        <input type="email" className="form-control" id="email" name="email" />
      </div>
      <div className="form-group">
        <label>Time Available:</label>
        <input
          type="text"
          className="form-control"
          id="time_available"
          name="time_available"
        />
      </div>
      <div className="form-group">
        <label>Budget:</label>
        <input type="text" className="form-control" id="budget" name="budget" />
      </div>
      <button type="submit" className="btn btn-primary">
        Submit
      </button>
    </form>
  );
};

export default Quote;
