import React, { useState } from "react";
import "./App.css";
import "./modal.css";
import Modal from "./Modal";

function Quotation({ closeQuotation, invokeQuotation }) {
  const [openModal, setOpenModal] = useState(false);

  return (
    <div>
      <button
        onClick={() => setOpenModal(true)}
        className="btn btn-light py-sm-3 px-sm-5 rounded-pill me-3 animated slideInLeft"
      >
        Ask for quotation
      </button>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        closeQuotation={closeQuotation}
        invokeQuotation={invokeQuotation}
      />
    </div>
  );
}

export default Quotation;
